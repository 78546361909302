import React from "react";
import { IoDocumentTextSharp } from "react-icons/io5";
import {
  ESSILORNL,
  ESSILORBE,
  OOGWERELDNL,
  OOGWERELDBE,
  PORTALUK,
  TEMATICA,
  VOLVO,
  OMP,
  BIKENL,
  ACOUSTICSNL,
  RODENSTOCKDE,
  EYECARENL,
  EGSDE,
  TEST,
  EYECAREBE,
} from "../constants.js";
import contentEssilorNL from "../content/essilorNL/contact.json";
import contentEssilorBE from "../content/essilorBE/contact.json";
import contentOogwereldNL from "../content/oogwereldNL/contact.json";
import contentOogwereldBE from "../content/oogwereldBE/contact.json";
import contentPortalUK from "../content/portalUK/contact.json";
import contentTematica from "../content/tematica/contact.json";
import contentVolvo from "../content/volvo/contact.json";
import contentOMP from "../content/OMP/contact.json";
import contentBikeNL from "../content/bikeNL/contact.json";
import contentAcousticsNL from "../content/acousticsNL/contact.json";
import contentRodenstock from "../content/rodenstock/contact.json";
import contentEyecareNL from "../content/eyecareNL/contact.json";
import contentEyecareBE from "../content/eyecareBE/contact.json";
import contentEGSDE from "../content/EGSDE/contact.json";
import { useTranslation } from "react-i18next";
import icons from "../icons.js";
import config from "../config.js";

const selectContent = (clientName) => {
  switch (clientName) {
    case TEST:
    case ESSILORNL:
      return contentEssilorNL;
    case ESSILORBE:
      return contentEssilorBE;
    case RODENSTOCKDE:
      return contentRodenstock;
    case OOGWERELDNL:
      return contentOogwereldNL;
    case OOGWERELDBE:
      return contentOogwereldBE;
    case PORTALUK:
      return contentPortalUK;
    case TEMATICA:
      return contentTematica;
    case VOLVO:
      return contentVolvo;
    case OMP:
      return contentOMP;
    case BIKENL:
      return contentBikeNL;
    case ACOUSTICSNL:
      return contentAcousticsNL;
    case EYECARENL:
      return contentEyecareNL;
    case EYECAREBE:
      return contentEyecareBE;
    case EGSDE:
      return contentEGSDE;
    default:
      return null;
  }
};

const ContactText = () => {
  const content = selectContent(config.clientCode);
  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <ul className="icon-list" style={{ marginBottom: 0 }}>
        {content.helpdeskPhone && (
          <li>
            {icons.phone}
            {t("ui.Helpdesk")}{" "}
            <a href={`tel:${content.helpdeskPhone}`}>{content.helpdeskPhone}</a>
          </li>
        )}
        {content.manualPdf && (
          <li>
            <a href={content.manualPdf} target="_blank" rel="noreferrer">
              <IoDocumentTextSharp />
              {t("ui.Manual")}
            </a>
          </li>
        )}
        {content.pricelistPdf && (
          <li>
            <a href={content.pricelistPdf} target="_blank" rel="noreferrer">
              <IoDocumentTextSharp />
              {t("ui.Pricelist")}
            </a>
          </li>
        )}
        {content.disclaimerPdf && (
          <li>
            <a href={content.disclaimerPdf} target="_blank" rel="noreferrer">
              <IoDocumentTextSharp />
              {t("ui.Disclaimer")}
            </a>
          </li>
        )}
        {content.conditionsPdf && (
          <li>
            <a href={content.conditionsPdf} target="_blank" rel="noreferrer">
              <IoDocumentTextSharp />
              {t("ui.Conditions")}
            </a>
          </li>
        )}
        {content.phone && (
          <li>
            {icons.phone}
            {t("ui.Call-us-to")}{" "}
            <a href={`tel:${content.phone}`}>{content.phone}</a>
          </li>
        )}
        {content.email && (
          <li>
            {content?.emailLabel ? (
              <div style={{ paddingLeft: "26px" }}>
                <span style={{ float: "left", marginLeft: "-26px" }}>
                  {" "}
                  {icons.email}
                </span>
                <span>{content?.emailLabel}</span>
                <br />
                <a href={`mailto:${content.email}`}>{content.email}</a>
              </div>
            ) : (
              <>
                {icons.email}
                {t("ui.Email-us-to")}{" "}
                <a href={`mailto:${content.email}`}>{content.email}</a>
              </>
            )}
          </li>
        )}
      </ul>
    </React.Fragment>
  );
};

export default ContactText;
