import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const createProof = ({
  id,
  onSuccess,
  email,
  parameters = {},
  onError = (error) => console.log(error),
}) => {
  const customerId = localStorage.getItem("customerId");
  const timestamp = new Date().getTime();
  return axios({
    method: "POST",
    data: {
      format: "jpg",
      highQuality: true,
      recipientEmail: !!email ? email : null,
      parameters: !!parameters ? parameters : null,
    },
    url: `${phoenix}/variants/${id}/proofs?timestamp=${timestamp}`,
    withCredentials: true,
    headers: {
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      if (!!res) {
        !!onSuccess && onSuccess(res?.data);
      } else {
        !!onError ? onError(res) : console.log("Request failed", res);
      }
    })
    .catch((error) => {
      if (error.response) {
        onError(error.response.data);
      } else if (error.request) {
        onError(error.request);
      } else {
        onError(error.message);
      }
    });
};

export default createProof;
